'use client';

import { Auth } from 'aws-amplify';
import { datadogRum } from '@datadog/browser-rum';
import React, { useEffect, useContext, createContext, useReducer, useState, Reducer, useMemo } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import { v4 as uuid } from 'uuid';
import { useAutoLogout } from '@hooks/useAutoLogout';

Auth.configure({
    authenticationFlowType: 'CUSTOM_AUTH',
    userPoolId: process.env.NEXT_PUBLIC_COGNITO_POOL,
    userPoolWebClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT,
});

// FIXME(LY) Add isSales here by parsing idToken rather than include in GET request
type User = {
    deviceKey: string;
    firstName: string;
    lastName: string;
    token: string;
    groups: string[];
};

type AuthContextType = { user: User | null, isAuthenticated: boolean, dispatch: React.Dispatch<AuthAction> };
export const AuthContext = createContext<AuthContextType>({ user: null, isAuthenticated: false, dispatch: () => null });
type AuthAction = { type: 'signIn', user: User } | { type: 'signOut' };
const authReducer: Reducer<Omit<AuthContextType, 'dispatch'>, AuthAction> = (state, action) => {
    switch (action.type) {
        case 'signIn':
            return {
                isAuthenticated: true,
                user: action.user,
            };
        case 'signOut':
            return {
                isAuthenticated: false,
                user: null,
            };
        default:
            return { ...state };
    }
};

/**
 * Top-level context provider. Should wrap any components using this hook
 */
export const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [{
        user,
        isAuthenticated
    }, dispatch] = useReducer(authReducer, { isAuthenticated: false, user: null });

    const context = useMemo(() => ({ user, isAuthenticated, dispatch }), [user, isAuthenticated, dispatch]);

    return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    const [loading, setLoading] = useState(true);
    const { dispatch, isAuthenticated, user } = useContext(AuthContext);
    const router = useRouter();
    const pathname = usePathname();

    const logout = async () => {
        sessionStorage.setItem('redirect', pathname);
        await Auth.signOut();
        dispatch({ type: 'signOut' });
        router.push(`/login?redirect=${encodeURIComponent(pathname)}`);
    }

    useAutoLogout({ isAuthenticated, logout });

    // Redirect if not logged in
    useEffect(() => {
        setLoading(true);
        Auth.currentAuthenticatedUser()
            .then(cognitoUser => {
                const deviceKey = cognitoUser.getSignInUserSession().getAccessToken().decodePayload().device_key
                    ?? localStorage.getItem('deviceKey')
                    ?? uuid();
                localStorage.setItem('deviceKey', deviceKey);
                dispatch({
                    type: 'signIn',
                    user: {
                        deviceKey,
                        firstName: cognitoUser.attributes.given_name,
                        lastName: cognitoUser.attributes.family_name,
                        token: cognitoUser.getSignInUserSession().getAccessToken().getJwtToken(),
                        groups: cognitoUser.getSignInUserSession().getIdToken().decodePayload()['cognito:groups'] ?? [],
                    },
                });
                datadogRum.setUser({
                    id: cognitoUser.attributes.sub,
                    name: `${cognitoUser.attributes.given_name} ${cognitoUser.attributes.family_name}`,
                    email: cognitoUser.attributes.email,
                    isStaff: cognitoUser.attributes['custom:is_app_installed'] && true,
                });
                setLoading(false);
            })
            .catch(error => {
                if (error !== 'The user is not authenticated') {
                    throw error;
                }
                router.push(`/login?redirect=${encodeURIComponent(pathname)}`);
            });
    }, [dispatch, pathname, router]);

    return { isAuthenticated, loading, user, logout };
};

